<template>
  <div class="dataM productData">
    <div class="content">
      <div class="title">
        <div>产品列表</div>
        <div class="tool">
          <upload 
            ref="upload"
            templateName="产品数据导入模板"
            templateParam="productInfo"
            @handleSearch="handleSearch"
            v-premiSub="'产品数据_导入'"
          />
          <!-- <upload @uploadFile="uploadFile" ref="upload"/> -->
          <!-- <img src="@/assets/images/dataManage/batchdel.png" @click="comfirm('')"/> -->
          <!-- <img src="@/assets/images/dataManage/add.png"/>
          <img src="@/assets/images/dataManage/copy.png"/> -->
          <el-input
            v-model="searchValue"
            style="width:220px"
            size="small"
            placeholder="请输入物料编码/名称/图号"
            v-premiSub="'产品数据_搜索'"
            @keydown.enter.native="handleSearch(1)"
            >
            <i slot="suffix" class="el-input__icon el-icon-search" @click="handleSearch(1)"></i>
          </el-input>
        </div>
      </div>
      <el-table 
        :data="tableData" 
        header-cell-class-name="tableHeader" 
        style="width: 100%" 
        @selection-change="handleSelectionChange"
        @row-dblclick="seeDetails"
      >
        <!-- <el-table-column type="selection" width="55"/> -->
        <el-table-column prop="showId" label="序号" width="100"/>
        <el-table-column prop="productName" label="物料名称" min-width="120"/>
        <el-table-column prop="materialNo" label="物料编码" min-width="120"/>
        <el-table-column prop="partCode" label="图号" min-width="120"/>
        <el-table-column prop="versionCode" label="版本号" min-width="120"/>
        <el-table-column prop="customerName" label="客户" min-width="120"/>
        <el-table-column prop="rawMaterial" label="材质" min-width="120"/>
        <el-table-column prop="shape" label="毛坯形状" min-width="120"/>
        <el-table-column prop="diameter" label="毛坯直径(mm)" min-width="120"/>
        <el-table-column prop="length" label="毛坯长(mm)" min-width="120"/>
        <el-table-column prop="width" label="毛坯宽(mm)" min-width="120"/>
        <el-table-column prop="height" label="毛坯高(mm)" min-width="120"/>
        <el-table-column prop="blankWeight" label="毛坯重量(kg)" min-width="120"/>
        <el-table-column prop="productWeight" label="成品重量(kg)" min-width="120"/>
        <el-table-column label="操作" fixed="right" min-width="120" align="center">
          <template slot-scope="scope">
            <div class="operation">
              <!-- <img src="@/assets/images/dataManage/edit.png" style="margin-right:10px"/> -->
              <!-- <img src="@/assets/images/dataManage/del.png"  @click="comfirm(scope.row)"/>
              <span style="margin:10px">|</span> -->
              <span style="color: #2DEB79;cursor:pointer" @click="seeDetails(scope.row)">查看工序</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <pagination :total="total" :page.sync="page" :size.sync="size"  @getTableData="handleSearch"/>
    </div>
    <resultDialog 
      name="产品数据" 
      :resultVisible.sync="resultVisible" 
      :type="resultType" 
      :fileName="fileName"
      :resultMsg="resultMsg"
      @reUpload="reUpload"
    />
  </div>
</template>

<script>
const pagination = () => import('@/components/pagination')
const upload = () => import('@/components/upload/upload')
const resultDialog = () => import('@/components/upload/resultDialog')
import {getProductInfo,delProduct,importProduct,delProductBatch} from '@/api/data.js'
import { isComfirm } from '@/utils/data.js'
export default {
  name: "productData",
  components:{
    pagination,
    upload,
    resultDialog
  },
  data() {
    return {
      tableData:[],
      total:0,
      page:1,
      size:10,
      searchValue:'',
      resultVisible:false,
      resultType:true,
      resultMsg:'',
      fileName:'',
      multipleSelection:[]
    };
  },
  watch:{
    size(){
      this.handleSearch(1)
    }
  },
  activated(){
    this.handleSearch()
  },
  methods:{
    handleSelectionChange(val){
      this.multipleSelection = val.map(row => row.id);
    },
    handleSearch(page){
      page ? this.page = page : this.page = 1;
      let params = {
        page:this.page,
        size:this.size,
        searchValue:this.searchValue
      }
      getProductInfo(params).then(res=>{
        if(res.code === 0){
          this.tableData = res.data.records
          this.total = res.data.total
        }else{
          this.$message.error(res.msg || '接口出错')
        }
      }).catch(()=>{
        this.$message.error('接口出错')
      })
    },
    seeDetails(row){
      this.$router.push({
        path:'editproductData',
        query:{
          partCode:row.partCode,
          partVersion:row.versionCode,
          id:row.id
        }
      })
    },
    comfirm(row){
      if(!row && !this.multipleSelection.length){
        this.$message.warning('请选择要删除的数据')
        return
      }
      isComfirm(row,row ? this.del : this.batchdel)
    },
    del(row){
      delProduct(row.id).then(res=>{
        if(res.code === 0){
          this.$message.success('删除成功')
          this.handleSearch()
        }else{
          this.$message.error(res.msg || '接口失败')
        }
      })
    },
    batchdel(){
      delProductBatch(this.multipleSelection).then(res=>{
        if(res.code === 0){
          this.$message.success('删除成功')
          this.handleSearch()
        }else{
          this.$message.error(res.msg || '接口失败')
        }
      })
    },
    uploadFile(file){
      let formData = new FormData();
      formData.append('file',file.raw);
      this.fileName = file.name
      importProduct(formData).then(res=>{
        this.resultMsg = res.msg
        if(res.code == 0){
          this.resultVisible = true
          this.resultType = true
          this.handleSearch()
        }else{
          this.resultVisible = true
          this.resultType = false
        }
      }).catch((error)=>{
        this.resultMsg = error.response?.data?.msg
        this.resultVisible = true
        this.resultType = false
      })
    },
    reUpload(){
      this.$refs.upload.reUpload()
    }
  }
};
</script>

<style lang="scss">
.productData{
  padding: 18px;
  color: #E8E8E8;
  .content{
    position: relative;
    background: #232323;
    min-height: calc(100vh - 280px);
    text-align: left;
    padding: 25px 35px 80px 35px;
    .tableHeader{
      background: #2C2C2C;
      color: #B9B9B9;
    }
    .title{
      font-size: 20px;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-between;
    }
    .tool{
      width: 300px;
      display: flex;
      align-items: center;
      img{
        margin: 0 15px;
        cursor: pointer;
        width: 22px;
        height: 22px;
      }
    }
    .operation{
      font-size: 16px;
      display: flex;
      align-items: center;
      span{
        display: inline-block;
        margin-left: 10px;
      }
    }
    img{
      width: 22px;
      height: 22px;
    }
  }
}
</style>
